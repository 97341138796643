import React from 'react';
import './engineeringMaincontent.css';
import { Parallax, ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';

const EngineeringMaincontent = () => {

  return (
    <ParallaxProvider>
      <div className="responsive-engineering">
        <br />
        <div style={{ marginTop: "100px" }}>
          <div className="RenewableEnergy-pc">
            <ParallaxBanner style={{ aspectRatio: "2 / 1", width: "100%" }}>
              <ParallaxBannerLayer
                image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/engineering/tile-01.jpg"
                speed={7}
              />
            </ParallaxBanner>
          </div>
          <div className="RenewableEnergy-mobile">
            <ParallaxBanner style={{ aspectRatio: "auto 360 / 540" }}>
              <ParallaxBannerLayer
                image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/engineering/tile-01.jpg"
                speed={10}
              />
            </ParallaxBanner>
          </div>
        </div>

        <div className="first-para-engineering">
          <p className="para">
            Every industry requires a tailored solution to meet its complex
            engineering requirements; Lalan Engineering specializes in
            delivering just that. From humble beginnings in 1995, our presence
            across several engineering disciplines today is owed to the diverse
            products and services portfolio readily available to our clientele,
            offering a range of technologically advanced, environmentally
            friendly, and sustainable engineering solutions. Our ambitious and
            passionate teams of qualified professionals guarantee the
            highest-quality standards, employing world-class technology, whilst
            understanding the importance of integrity and effective resource
            management.
            <br />
            <br />
            - At Lalan Engineering, we undertake: <br />
            - Turnkey Projects <br />
            - Operation & Maintenance <br />
            - Annual Service Contracts <br />
            - Chemical & Synthetic Fluid products and related services <br />
            - Automation <br />
            - Consultancy <br />
            - Capital equipment and Consumables <br />
            <br />
            While we are reputed for our expertise in Steam Boilers and Thermic
            Fluid Heaters, we similarly excel at Hot Water and Hot Air
            Generators, Chillers, Water Treatment plants, MS & SS Pipelines and
            Tanks, and Automation.
            <br /> <br /> <br /> <br /> <br /> <br />
          </p>
        </div>

        <div className="engineering-para">
          <div>
            <div className="two-column">
              <div className="column-2">
                {/* Column 1 */}
                <br /> <br />
                <Parallax speed={0}>
                  <div className="engineering-pc">
                    <ParallaxBanner style={{ aspectRatio: "2 / 2" }}>
                      <ParallaxBannerLayer
                        image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/engineering/tile-02-a.jpg"
                        speed={10}
                      />
                    </ParallaxBanner>
                  </div>
                  <div className="engineering-mobile">
                    <ParallaxBanner style={{ aspectRatio: "2 / 2.8" }}>
                      <ParallaxBannerLayer
                        image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/engineering/tile-02-a.jpg"
                        speed={10}
                      />
                    </ParallaxBanner>
                  </div>
                  <br />
                  <h1 className="topic">Automation</h1>
                  <p className="para">
                    We recognized the benefits and opportunities Sri Lanka's SME
                    sector would experience by gradually transitioning to
                    Industry 4.0. In 2020, the JV partnership with Ranade's S R
                    Systems (India) formed Lalan S R Automation Systems to enter
                    the automation solution sphere. We have encouraged,
                    introduced, and successfully implemented technologically
                    driven organic growth and advancement in the face of
                    emerging competition; and motivated customers to compete
                    globally. An advantage of the partnership lies in the
                    authorized agency line for a selection of Siemens products,
                    such as: PLCs, HMIs SCADA, VFDs, and Servo Drives, to name a
                    few. In addition, System Designing, Detailed Engineering,
                    Programming, Commissioning, and Support services are the
                    accompanying amenities offered, thus ensuring an end-to-end
                    automated solution.
                  </p>
                </Parallax>
              </div>

              <div className="column-2">
                {/* Column 2 */}
                <br />
                <br />
                <br />
                <Parallax speed={40}>
                  <div className="engineering-pc">
                    <ParallaxBanner style={{ aspectRatio: "2 / 2" }}>
                      <ParallaxBannerLayer
                        image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/engineering/tile-03.jpg"
                        speed={10}
                      />
                    </ParallaxBanner>
                  </div>
                  <div className="engineering-mobile">
                    <ParallaxBanner style={{ aspectRatio: "2 / 2.8" }}>
                      <ParallaxBannerLayer
                        image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/engineering/tile-03.jpg"
                        speed={10}
                      />
                    </ParallaxBanner>
                  </div>
                  <br />
                  <h1 className="topic">Consultancy</h1>
                  <p className="para">
                    Lalan Engineering consults on global projects in addition to
                    local developments. We specialize in optimization of Thermal
                    Energy costs, Designing and Engineering of Utility
                    Pipelines, and Thermic Fluid Systems; ensuring optimal
                    efficiency with upgraded technology from our network of
                    partners.
                    <br />
                    <br />
                    The lasting relationships we have sustained with our network
                    of globally renowned partners guarantee our customers high
                    quality, reliable, and efficient capital and consumables
                    items, to assist in their pursuit of technological
                    advancements, productive efficiency, and competition.
                    <br />
                    <br />
                    Authorized Agents for:
                    <br />
                    THERMAX / SOLUTIA / TEA MECH / SIEMENS / HOLTZMAN / CARRIER
                    / TRANTER
                    <br />
                    <br />
                    In a hyper-competitive era with dynamic technological
                    changes, we are determined to provide cost-effective
                    solutions whilst reducing the carbon footprint. Our 500+
                    customer base is a true testimony to our technical
                    excellence and high-quality value-added services.
                    <br />
                    <br />
                  </p>
                  <p className="address">
                    Lalan Engineering (Private) Limited. <br />
                    344, Grandpass Road, <br />
                    Colombo 14, <br />
                    Sri Lanka. <br />
                    customercare.lepl@lalangroup.com <br />
                    (+94) 11 461 4211 <br />
                    <br />
                  </p>
                </Parallax>
              </div>
            </div>
          </div>

          <div className="engineering-pc" style={{ marginTop: "200px" }}>
            <center>
              <h1 className="bigHugeTitle">
                9 Sectors <br />
                of Integrated <br />
                <span
                  style={{
                    color: "#46708a",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Expertise
                </span>
              </h1>
              <div style={{ marginTop: "100px", marginBottom: "200px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "200px",
                  }}
                >
                  <div style={{ width: "2px", background: "#46708a" }}></div>
                </div>
              </div>
            </center>
          </div>

          <div
            className="engineering-mobile"
            style={{ marginTop: "-180px", marginBottom: "300px" }}
          >
            <p className="text-max-engineering">
              9 Sectors of Integrated
              <span
                style={{
                  color: "#46708a",
                  fontStyle: "italic",
                  fontWeight: "bold",
                }}
              >
                Expertise
              </span>
            </p>
          </div>
        </div>
      </div>
    </ParallaxProvider>
  );
};

export default EngineeringMaincontent;
