import React, { useEffect, useRef } from 'react';
import './engineeringMaincontent.css'; // Create this CSS file to style the container

const Slideshow = () => {
  const containerRef = useRef(null);
  const scrollStep = 2; // Adjust the scroll step here (change 2 to desired speed)

  // Variables for drag functionality
  let isDragging = false;
  let startPos = 0;
  let currentScrollLeft = 0;

  // Scroll function
  const scrollContainer = () => {
    if (!isDragging && containerRef.current) {
      containerRef.current.scrollLeft += scrollStep;
      // Check if we've reached the end and reset the scroll position to the beginning
      if (containerRef.current.scrollLeft >= containerRef.current.scrollWidth - containerRef.current.clientWidth) {
        containerRef.current.scrollLeft = 0;
      }
    }
  };

  // Auto-scroll on load
  useEffect(() => {
    const interval = setInterval(scrollContainer, 50); // Adjust interval (ms) here for smoothness
    return () => clearInterval(interval);
  }, []);

  // Event handlers for dragging
  const handleMouseDown = (e) => {
    isDragging = true;
    startPos = e.clientX || e.touches[0].clientX;
    currentScrollLeft = containerRef.current.scrollLeft;
    // Add event listeners for mousemove and mouseup (document-wide) during dragging
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;

    let clientX = 0;
    if (e.clientX !== undefined) {
      // Mouse event
      clientX = e.clientX;
    } else if (e.touches && e.touches.length > 0) {
      // Touch event
      clientX = e.touches[0].clientX;
    }

    const delta = clientX - startPos;
    containerRef.current.scrollLeft = currentScrollLeft - delta;
  };


  const handleMouseUp = () => {
    isDragging = false;
    // Remove event listeners for mousemove and mouseup when dragging is done
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  return (
    <div
      className="scroll-container"
      ref={containerRef}
      onMouseDown={handleMouseDown}
      onTouchStart={handleMouseDown}
    >
      <img
        src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/engineering/gal-01.jpg"
        alt="1"
      />
      <img
        src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/engineering/gal-02.jpg"
        alt="2"
      />
      <img
        src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/engineering/gal-03.jpg"
        alt="3"
      />
      <img
        src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/engineering/gal-04.jpg"
        alt="4"
      />
      <img
        src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/engineering/gal-05.jpg"
        alt="5"
      />
      <img
        src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/engineering/gal-06.jpg"
        alt="6"
      />

      <img
        src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/engineering/gal-01.jpg"
        alt="1"
      />
      <img
        src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/engineering/gal-02.jpg"
        alt="2"
      />
      <img
        src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/engineering/gal-03.jpg"
        alt="3"
      />
      <img
        src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/engineering/gal-04.jpg"
        alt="4"
      />
      <img
        src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/engineering/gal-05.jpg"
        alt="5"
      />
      <img
        src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/engineering/gal-06.jpg"
        alt="6"
      />
    </div>
  );
};

export default Slideshow;