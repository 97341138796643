import React, { useState, useRef, useEffect } from "react";
import NavBar from "../../components/navbar";
import "../../components/homevideo.css";
import "../../components/mobile-responsive.css";
import Slideshow from "../../template/Engineering/Slideshow";
import EngineeringMaincontent from "../../template/Engineering/engineeringMaincontent";
//import vote from "../../images/bestweb-24-vote4us.webp"; //  BestWeb - logo
import TopWeb from "../../images/TopWeb-May-2024.jpg"; //  TopWeb - logo
import Footer3CS from "../../components/footerbar";
import Arrow from "../../template/ScrollToTopButton";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import { Helmet } from "react-helmet";

const Engineering = () => {
  //  BestWeb - start
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsMobile(window.innerWidth <= 600);

      const handleResize = () => {
        setIsMobile(window.innerWidth <= 600);
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  //  BestWeb - end

  const blueLineHeight = 200;
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // const openTab = () => {
  //   window.open("https://vote.bestweb.lk/", "_blank");
  // };

  const mainRef = useRef(null);

  const handleClick = () => {
    const yOffset = -50; // You can adjust this value as needed
    const y =
      mainRef.current.getBoundingClientRect().top +
      window.pageYOffset +
      yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  const calculateBlueLineHeight = () => {
    if (typeof window === "undefined" || typeof document === "undefined") {
      return "0px";
    }
    const maxScrollPosition =
      document.documentElement.scrollHeight - window.innerHeight;
    const clampedScrollPosition = Math.min(scrollPosition, maxScrollPosition);
    const scrollPercentage = (clampedScrollPosition / maxScrollPosition) * 100;
    const calculatedBlueLineHeight = (scrollPercentage / 100) * blueLineHeight; // Adjust this value as needed
    return `${calculatedBlueLineHeight}px`;
  };

  return (
    <ParallaxProvider>
      <div style={{ backgroundColor: "#121718" }}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Engineering | Lalan Group Sri Lanka</title>
          <meta name="title" content="Engineering | Lalan Group Sri Lanka" />
          <meta
            name="description"
            content="Established in 1995, lalan group's engineering arm has over 500 clients provided with effective energy solutions that are focused on carbon footprint reduction and clean emissions. "
          />
          <meta name="keywords" content="Engineering, Clean Emissions" />
          <link
            rel="canonical"
            href="https://www.lalangroup.com/sectors/engineering/"
          />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
          <meta
            property="og:title"
            content="Engineering - Diversified Conglomerates in Sri Lanka | Lalan Group"
          />
          <meta
            property="og:url"
            content="https://www.lalangroup.com/sectors/engineering/"
          />
          <meta
            property="og:site_name"
            content="Diversified Conglomerates in Sri Lanka | Lalan Group"
          />
        </Helmet>

        <div className="scroll-bar-responsive">
          <div
            style={{
              marginRight: "70px",
              position: "fixed",
              right: "0",
              top: "calc(50% - 100px)",
              height: "200px",
              width: "4px",
              background: "#ffffff",
              opacity: "0.2",
              zIndex: 11,
              borderRadius: "40px",
            }}
          />
          <div
            style={{
              marginRight: "70px",
              position: "fixed",
              right: "0",
              top: "calc(50% - 100px)",
              height: calculateBlueLineHeight(),
              width: "4px",
              background: "#fff",
              opacity: "0.34",
              zIndex: 11,
              borderRadius: "40px",
            }}
          />
        </div>

        <NavBar />
        <div style={{ position: "relative" }}>
          <img
            className="background-video"
            src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/serverless/top-banner3.jpg"
            alt="background-video"
          />
          {/* Media query for screens up to 900px */}
          <style>
            {`@media (max-width: 900px) {
            .background-video {
              content: url(${"https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/serverless/top-banner3-II.jpg"});
            }
          }`}
          </style>
          <div className="top-font-block">
            <Parallax speed={10}>
              <img
                className="video-font-png"
                src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/serverless/engineering.png"
                alt="font"
              />
            </Parallax>
          </div>
          <div
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              bottom: "13px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="mouse-color">
              <img
                onClick={handleClick}
                src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/uploads/images/gif-mouse.gif"
                alt="button"
                className="mouse-button"
                style={{ height: "90px", zIndex: 10 }}
              />
            </div>
          </div>
        </div>

        <div ref={mainRef}>
          {/* Main content */}
          <div
            style={{
              marginLeft: "170px",
              marginRight: "170px",
              marginTop: "30px",
              marginBottom: "-100px",
            }}
          >
            <EngineeringMaincontent />
          </div>
        </div>

        <div className="swipe-pc">
          <div className="swipeWrapper">
            <div className="swipe">
              <div className="arrow">
                <svg width="71.5px" height="27.17px" viewBox="0 0 71.5 27.17">
                  <path
                    className="st0"
                    d="M70.75,12.67h-2.96c-7.47-0.91-13.21-5.9-13.21-11.92c0-0.41-0.34-0.75-0.75-0.75s-0.75,0.34-0.75,0.75 c0,5.14,3.62,9.61,8.95,11.92H8.47c5.33-2.31,8.95-6.78,8.95-11.92c0-0.41-0.34-0.75-0.75-0.75s-0.75,0.34-0.75,0.75 c0,6.01-5.73,11-13.2,11.92H0.75c-0.17,0-0.32,0.07-0.45,0.16c-0.02,0-0.04,0-0.05,0v0.03C0.1,13.01,0,13.2,0,13.42 s0.1,0.41,0.25,0.55v0.36c8.64,0,15.67,5.42,15.67,12.08c0,0.41,0.34,0.75,0.75,0.75s0.75-0.34,0.75-0.75c0-5.4-4-10.06-9.78-12.25 h55.22c-5.77,2.19-9.77,6.85-9.77,12.25c0,0.41,0.34,0.75,0.75,0.75s0.75-0.34,0.75-0.75c0-6.66,7.03-12.08,15.67-12.08v-0.16h0.5 c0.41,0,0.75-0.34,0.75-0.75S71.16,12.67,70.75,12.67z"
                  />
                </svg>
              </div>
              <div
                className="circle"
                style={{ position: "relative", zIndex: "13" }}
              >
                <div
                  className="innerCircle"
                  style={{
                    position: "absolute",
                    right: "0",
                    bottom: "0",
                    marginRight: "200px",
                    marginBottom: "-15px",
                  }}
                >
                  <img
                    src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/sample-images/circke-arow.png"
                    data-src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/sample-images/circke-arow.png"
                    decoding="async"
                    className="ls-is-cached lazyloaded rotating-image"
                    alt="Circle Arrow"
                    style={{
                      width: "150px",
                      height: "150px",
                      objectFit: "cover",
                      animation: "rotate 100s infinite linear",
                    }}
                  />
                  <img
                    src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/sample-images/double-arrow_318-155268.avif"
                    alt="Double Arrow"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "70px",
                    }}
                  />
                  <noscript>
                    <img
                      src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/sample-images/circke-arow.png"
                      data-eio="l"
                      alt="Circle Arrow"
                    />
                  </noscript>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Slideshow />

        <div style={{ display: "flex", flexDirection: "row" }}>
          {/* BestWeb - start */}
          {/* <div>
            <a
              href="https://www.vote.bestweb.lk/site/www_lalangroup_lk"
              target="_blank"
            >
              <img
                style={{
                  marginBottom: isMobile ? "0px" : "40px",
                  marginLeft: isMobile ? "30px" : "80px",
                }}
                className="logopic"
                src={vote}
                alt="logo"
              />
            </a>
          </div> */}
          {/* BestWeb - end */}

          {/* TopWeb - start */}
          <div>
            <a href="https://topweb.lk/may2024/lalangroup" target="_blank">
              <img
                style={{
                  marginBottom: isMobile ? "0px" : "40px",
                  marginLeft: "30px",
                }}
                className="logopic-2"
                src={TopWeb}
                alt="logo"
              />
            </a>
          </div>
          {/* TopWeb - end */}
        </div>

        <div style={{ marginTop: "100px" }}>
          <Footer3CS />
        </div>

        <Arrow />
      </div>
    </ParallaxProvider>
  );
};

export default Engineering;
